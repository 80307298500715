
.headerNavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: #fff;
    padding: 0 4rem;
}


.headerNavbar .logo {
    font-size: 1.5rem;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
}

.logo img{
    width: 3rem;
    height: 3rem;
}

.headerNavbar .navbarItems {
    list-style: none;
    padding: 0;
}

.headerNavbar .navbarItems ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbarItems ul a{

    color: #ffffff;
    text-decoration: none;
}

.headerNavbar .navbarItems ul li {
    margin-right: 1rem;
    margin-left: 1rem;
}

.headerNavbar ul li:hover {
    cursor: pointer;
}

.active {
    color: red;
}

.rotationBox .rotation {
    width: 100%;
}

.solutionBox {
    padding-bottom: 2rem;
}

.solutionTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.solutionTitle h4 {
    margin-top: 0;
}

.solutionList {
    width: 100%;
    box-sizing: border-box;
    padding: 0 4rem;
    margin: 4rem 0 2rem 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.cardBox {
    width: 25%;
    height: 15rem;
    box-sizing: border-box;
    margin: 0 4px;
    background-color: #999;
    position: relative;
}

.card {
    color: #ffffff;
}

b {
    width: 103px;
    height: 103px;
    background: url(../../images/solution.png) repeat-x;
    position: absolute;
    left: 50%;
    top: -40px;
    margin-left: -51px;
    z-index: 2;
}


.card .b1 {
    background-position: 0 0;
}

.card .b2 {
    background-position: 309px 0px;
}

.card .b3 {
    background-position: 206px 0px;
}

.card .b4 {
    background-position: 103px 0px;
}

.card .cardContent {
    width: 100%;
    box-sizing: border-box;
    padding: 3rem 1rem;
    text-align: center;
}

.card .cardFooter {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
    background-color: #f97368;
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.solutionBox .solutionMore {
    display: flex;
    justify-content: center;
    align-items: center;
}

.solutionMore a {
    padding: 0.5rem 3rem;
    border: 2px solid #dbdbdb;
    color: #dbdbdb;
}

.solutionMore a:hover {
    border: 2px solid #999;
    background-color: #999;
    color: #ffffff;
    cursor: pointer;
}


/**
服务流程
 */
.serviceProcess {
    background-color: #dbdbdb;
}

.serviceProcessTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.serviceProcessTitle p {
    margin-top: 0;
}

.serviceProcessContent ul{
        margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.serviceProcessContent ul li {
    list-style: none;
    width: 14.28%;
    padding: 0 1rem 5rem 0;
}

.serviceProcessContent ul li a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.serviceProcessContent ul li a:hover {
    cursor: pointer;
}

a p {
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

a h3 {
    margin: 1rem 0 0.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.IconBox {
    width: 6rem;
    height: 6rem;
    background-color: #ffffff;
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
}

.circle {
    width: 6rem;
    height: 6rem;
    background-color: #ffffff;
    border-radius: 50%;
    box-sizing: border-box;
    z-index: 998;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        background-color: #007bff;
        transform: rotate(360deg);
    }
}

.Icon {
    width: 100%;
    height: 100%;
    z-index: 999;
    background-image: url('../../images/1.png');
    background-repeat: no-repeat;
    background-position: center;

    position: absolute;
    left: 0;
    top: 0;
}

.Icon2 {
    background-image: url('../../images/2.png');
}

.Icon3 {
    background-image: url('../../images/3.png');
}
.Icon4 {
    background-image: url('../../images/4.png');
}
.Icon5 {
    background-image: url('../../images/5.png');
}
.Icon6 {
    background-image: url('../../images/6.png');
}
.Icon7 {
    background-image: url('../../images/7.png');
}

.contactContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactList{
    width: 100%;
    padding: 4rem 0;
    background-color: #5f5f5f;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.contactCard{
    width: 12%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactCardCircle{
    border: 1px solid #ffffff;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.contactCard img{
    width: 4rem;
    height: 4rem;
    padding: 1rem;
}

.contactCard p{
    color: #ffffff;
    text-align: center;
}

.ownerContainer{
    background-color: #2a2a2a;
}

.ownerBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ownerBox p,a{
    color: #ffffff;
    font-size: 0.8rem;
}
